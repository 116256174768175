import { qs, _, addElement, transliterate, emitFilterChangeEvent } from '../../utils/utils';
import axios from 'axios';

export const initLocationFilters = Dom => {
    let {
        isOnlineCheckbox,
        area,
        place,
        placePlaceholder,
        placeDropdown,
        clearPlacesBtn,
        searchPlaceBtn
    } = Dom;

    // Handle is online toggle checkbox
    toggleLocationFilters(isOnlineCheckbox, area, placePlaceholder, clearPlacesBtn)
    isOnlineCheckbox.addEventListener('change', e => {
        toggleLocationFilters(e.target, area, placePlaceholder, clearPlacesBtn)

        emitFilterChangeEvent(e.target)
    })

    let placeTo = null;

    let placeItems = _(placeDropdown.children);

    area.addEventListener('change', e => {
        let slug = e.target.value;

        if (slug == '' || slug == 'all') {
            // placeDropdown.setHTML('');
            placeDropdown.innerHTML = '';
            placePlaceholder.value = '';
            place.value = '';

            return emitFilterChangeEvent(e.target)
        }

        emitFilterChangeEvent(e.target)

        axios.get(`/areas/s/${slug}/places`)
            .then(response => {
                    placePlaceholder.value = '';

                    renderPlaces(
                        response,
                        place,
                        placeDropdown,
                        placeItems
                    );
                })
    });

    placePlaceholder.addEventListener('keyup', e => {
        let term = e.target.value.trim();

        if (area.value)
            return filterPlaces(term, placeItems);

        clearTimeout(placeTo);

        if (term.length) {
            placeTo = setTimeout(() => {
                searchPlaceBtn.style.display = ''

                axios.get(`/places/search/${term}`)
                    .then(response => {
                        renderPlaces(
                            response,
                            place,
                            placeDropdown,
                            placeItems
                        );

                        searchPlaceBtn.style.display = 'none'
                    });
            }, 850);
        } else {
            // placeDropdown.setHTML('');
            placeDropdown.innerHTML = '';
        }
    });

    // searchPlaceBtn.addEventListener('click', e => {
        // emitFilterChangeEvent(e.target)
        // setTimeout(() => {
        //     placeDropdown.classList.add('opened');
        // })
    // })

    placePlaceholder.addEventListener('focus', e => {
        placeDropdown.classList.add('opened');
    });
    placePlaceholder.addEventListener('click', e => {
        placeDropdown.classList.add('opened');
    });

    placePlaceholder.addEventListener('blur', e => {
        setTimeout(() => {
            placeDropdown.classList.remove('opened');
        }, 200)
    });

    placeDropdown.addEventListener('click', e => {
        if (!e.target.classList.contains('place-li')) return null

        placeItems.removeClass('place-active');
        e.target.classList.add('place-active');
        placePlaceholder.value = e.target.dataset.slug != '' ?
            e.target.dataset.name : '';
        place.value = e.target.dataset.slug

        emitFilterChangeEvent(e.target)
    });

    clearPlacesBtn.addEventListener('click', e => {
        area.selectedIndex = 0;

        placePlaceholder.value = '';
        place.value = '';
        placeItems.removeClass('place-active');

        placeDropdown.innerHTML = '';

        emitFilterChangeEvent(e.target)
    });
}

const toggleLocationFilters = (isOnline, area, place, clearPlacesBtn) => {
    let disabled = isOnline.checked
    area.disabled = disabled
    place.disabled = disabled
    clearPlacesBtn.disabled = disabled
}

const addPlaceElement = (wrap, place) => {
    let element = addElement(wrap, 'li', ((place.type ? place.type + ' ' : '') + place.name), [
        { name: 'class', val: 'place-li' },
        { name: 'data-slug', val: place.slug },
        { name: 'data-name', val: (place.type ? place.type + ' ' : '') + place.name },
    ]);
    return element;
}

const filterPlaces = (term, placeItems) => {
    let searchTerm = transliterate(term);

    placeItems.removeClass('place-filtered');
    placeItems.loop(item => {
        let includes = item.dataset.name ?
            item.dataset.name.toLocaleLowerCase().includes(searchTerm) : false;

        if (!includes) {
            item.classList.add('place-filtered')
        }
    });
}

const renderPlaces = (response, place, placeDropdown, placeItems) => {
    place.value = '';
    // placeDropdown.setHTML('');
    placeDropdown.innerHTML = '';

    if (response.data.length) {
        response.data.forEach(place => {
            addPlaceElement(placeDropdown, place);
        });
    } else {
        let element = addElement(placeDropdown, 'li', 'Няма такива градове и села');
        element.style.pointerEvents = 'none'
        element.style.padding = '1rem'
    }

    placeItems = _(placeDropdown.children);
}
