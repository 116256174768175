import axios from "axios"
import { qs } from "../utils/utils"
import { showToast } from '../modules/toasts'
import { hidePageLoader, showPageLoader } from "./loaders"

export const toggleEventSave = () => {
    // Action toasts
    let eventList = qs('.events-list')
    if (eventList) {
        eventList.addEventListener('click', detectSaveBtnClick)
    }

    let eventSinglePage = qs('.event-single')
    if (eventSinglePage) {
        eventSinglePage.addEventListener('click', detectSaveBtnClick)
    }

    // Organizer follow
    let org = qs('.organizer-toggle-follow')
    if (org) {
        org.addEventListener('click', async e => {
            let el = e.target
            let action = el.dataset.action

            if (!action) return null

            showPageLoader()

            await axios.post(`/organizers/${el.dataset.id}/${action}`)

            hidePageLoader()

            if (action === 'follow') {
                el.classList.remove('btn-emerald')
                el.classList.add('btn-press')
                el.dataset.action = 'unfollow'
                el.innerHTML = `
                    <svg class="btn-icon"><use href="#unfollow-user"></use></svg>
                    Не следвайте
                `
            }

            if (action === 'unfollow') {
                el.classList.add('btn-emerald')
                el.classList.remove('btn-press')
                el.dataset.action = 'follow'
                el.innerHTML = `
                    <svg class="btn-icon"><use href="#follow-user"></use></svg>
                    Последвайте
                `
            }
        })
    }
}

const detectSaveBtnClick = e => {
    if (e.target.classList.contains('event-box-book'))
        return toggleSaveEvent(e.target)

    if (e.target.classList.contains('event-box-attend'))
        return toggleSaveEvent(e.target)
}

const toggleSaveEvent = async elem => {
    let activeClass = 'is-active'

    let isAttend = elem.dataset.action === 'attend'
    let isActive = elem.classList.contains(activeClass)
    let action = (isActive ? 'un' : '') + elem.dataset.action

    showPageLoader()

    await axios.post(`/events/${elem.dataset.url}/${action}`)

    if (isActive) {
        elem.classList.remove(activeClass)
        if (!isAttend)
            qs('.event-box-attend', elem.parentElement).classList.remove(activeClass)
    } else {
        elem.classList.add(activeClass)
        if (isAttend)
            qs('.event-box-book', elem.parentElement).classList.add(activeClass)
    }

    hidePageLoader()

    if (isActive) {
        return elem.dataset.action === 'book' ?
            showToast('Премахнахте събитието от запазени', 'top')
            : showToast('Премахнахте желание за участие', 'top')
    }

    return elem.dataset.action === 'book' ?
        showToast('Запазено в профила', 'top')
        : showToast('Заявихте желание за участие', 'top')
}
