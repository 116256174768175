import { emitFilterChangeEvent, qs, qsAll } from "../../utils/utils"
import { makeDatepicker } from "../datepicker/datepicker"

export const initDateFilters = Dom => {
    let dpMin, dpMax;

    let minTo = maxTo = null

    dpMin = makeDatepicker('#date_from_start', {
        minDate: new Date(),
        dateFormat: 'MM-dd-yyyy',
        onSelect({ date }) {
            dpMax.update({
                minDate: date
            })

            chooseRangeDate(date, dpMin, Dom)
        },

        onShow() {
            clearTimeout(minTo)
            clearTimeout(maxTo)
        },

        onHide() {
            // minTo = setTimeout(() => {
            //     emitFilterChangeEvent(dpMin.$el)
            // }, 2000)
        }
    })

    dpMax = makeDatepicker('#date_from_end', {
        minDate: new Date(),
        dateFormat: 'MM-dd-yyyy',
        onSelect({ date }) {
            dpMin.update({
                maxDate: date
            })

            chooseRangeDate(date, dpMax, Dom)
        },

        onShow() {
            clearTimeout(minTo)
            clearTimeout(maxTo)
        },

        onHide() {
            // maxTo = setTimeout(() => {
            //     emitFilterChangeEvent(dpMin.$el)
            // }, 2000)
        }
    })

    // On radio choice
    Dom.dateRadiosWrap.addEventListener('change', e => {
        if (e.target.classList.contains('radio-off')) return;

        clearPickers(Dom, dpMin, dpMax)

        // Send request
        emitFilterChangeEvent(e.target)
    })

    // Reset date filters
    Dom.filtersOff.addEventListener('click', e => {
        Dom.dateOff.checked = true

        clearPickers(Dom, dpMin, dpMax)

        emitFilterChangeEvent(e.target)
    })

    // Send request on btn click
    Dom.datesFiltersBtn.addEventListener('click', e => {
        emitFilterChangeEvent(e.target)
        Dom.dateOff.checked = false
    })
}

const chooseRangeDate = (date, datepicker, Dom) => {
    qs('.custom-date-val', datepicker.$el.parentElement)
        .innerHTML = datepicker.formatDate(date, 'd MMM yyyy')

    Dom.dateOff.checked = true

    qs('.dates-filter-ranges').classList.add('toggled')

    Dom.datesFiltersBtn.disabled = false
}

const clearPickers = (Dom, min, max) => {
    min.clear({
        silent: true
    })
    max.clear({
        silent: true
    })

    qsAll('.custom-date-val').forEach(item => item.innerHTML = '')

    qs('.dates-filter-ranges').classList.remove('toggled')

    Dom.dateFrom.value = null
    Dom.dateEnd.value = null

    Dom.datesFiltersBtn.disabled = true
}
