import { qs } from '../utils/utils'

export const createModal = (id, title, content, listener, classes) => {

    let html = `
        <div class="modal ${classes}">
            <button class="modal-close-btn modal-close-js">
                <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24">
                    <path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/>
                </svg>
            </button>

            <div class="modal-head">
                <h4>${title}</h4>
            </div>

            <div class="modal-body">${content}</div>
        </div>`

    let modal = qs('.modal-bg')
    if (!modal) {
        modal = document.createElement('div')
    } else {
        let new_modal = modal.cloneNode(true)
        modal.parentNide.replaceChild(new_modal, modal)
        modal = new_modal
    }

    modal.setAttribute('id', id)
    modal.setAttribute('class', 'modal-bg ')
    modal.innerHTML = html

    document.body.append(modal)

    modal.addEventListener('click', e => {
        let el = e.target
        if (el.classList.contains('modal-close-js') || el.closest('.modal-close-js')) {
            // close modal
            return modal.remove()
        }

        if (listener) {
            listener(e, el)

            return modal.remove()
        }
    })

    return modal
}

export const createConfirmModal = (id, title, message, confirmCallback, classes) => {
    let content = `
        <h3 class="text-center">${message}</h3>

        <div class="flex-1 mt-2">
            <button
                class="btn btn-cta btn-bg btn-modal modal-close-js"
            >
                Отказ
            </button>

            <button
                class="btn btn-cta btn-emerald btn-modal modal-confirm-btn"
            >
                Да
            </button>
        </div>`

    let modal = createModal(id, title, content, (e, el) => {
        if (el.classList.contains('modal-confirm-btn'))
            return confirmCallback(e, el)
    }, `modal-confirm ${classes}`)

    return modal
}
