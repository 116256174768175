export const qs = (selector, wrap = null) => {
    let item = wrap || document;
    return item.querySelector(selector);
}

export const qsAll = (selector, wrap = null) => {
    let item = wrap || document;
    return item.querySelectorAll(selector);
}

const compound = (items) => {
    let object = {
        items,

        addClass: (className) => {
            Array.from(items).forEach(item => {
                item.classList.add(className);
            });
        },

        removeClass: (className) => {
            Array.from(items).forEach(item => {
                item.classList.remove(className);
            });
        },

        loop: (cb) => {
            Array.from(items).forEach(item => {
                cb(item)
            });

            return object;
        }
    }

    return object;
}

export const _ = (selector, wrap = null) => {
    let parent = wrap || document;
    let items = typeof selector === 'object' ?
        selector : parent.querySelectorAll(selector);
    return compound(items);
}

export const addElement = (wrap, tagName = 'div', content = '', attributes = []) => {
    const newElement = document.createElement(tagName);

    // and give it some content
    if (content) {
        const newContent = document.createTextNode(content);
        // add the text node to the newly created div
        newElement.appendChild(newContent);
    }

    attributes.forEach(attr => {
        newElement.setAttribute(attr.name, attr.val)
    });

    // add the newly created element and its content into the DOM
    wrap.appendChild(newElement);

    return newElement;
}

export const transliterate = term => {
    let lowered = term.toLocaleLowerCase();

    let cyr = ['щ',  'ш', 'ч',  'ц', 'ю', 'я', 'ж', 'а','б','в','г','д','е','ё','з','и','й','к','л','м','н','о','п','р','с','т','у','ф','х','ь','ы','ъ','э'];
    let lat = ['sht','sh','ch','ts','yu','ya','zh', 'a','b','v','g','d','e','e','z','i','y','k','l','m','n','o','p','r','s','t','u','f','h','y','j','a','e'];

    if (/[a-zA-Z]/.test(lowered) === false) return lowered;

    let bg = lowered.slice();

    lat.forEach(item => {
        if (bg.includes(item)) {
            let index = lat.indexOf(item);
            bg = bg.replaceAll(item, cyr[index]);
        }
    });

    return bg;
}

export const emitEvent = (elem, eventName) => {
    const event = new Event(eventName, { bubbles: true })
    elem.dispatchEvent(event)
}

export const emitFilterChangeEvent = elem => {
    emitEvent(elem, 'filter-changed')
}

export const getQueryParameters = () => {
    return new URLSearchParams(window.location.search);
}

export const getQueryParameter = name => {
    let params = new URLSearchParams(window.location.search);
    return params.get(name);
}

export const changeQsUrl = (queryString) => {
    let { location } = window
    let newUrl = `${location.origin}${location.pathname}?${queryString}`
    window.history.pushState({}, '', newUrl);
}
