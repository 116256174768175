import { qs, qsAll, _, addElement } from '../../utils/utils';

export const auth = () => {

    // Handle verification
    let url = new URL(location.href)
    if (url.searchParams.get('verified') == '1') {
        alert('Account verified')
        // remove from parameters
        url.searchParams.delete('verified')
        history.replaceState(null, '', url.href)
    }

    // Auth login
    let loginForm = qs('.login-form')
    if (loginForm) {
        loginForm.addEventListener('submit', e => {
            e.preventDefault()
            validateForm(loginForm, ['email', 'password'])
        })
    }

    // Auth register
    let regForm = qs('.reg-form')
    if (regForm) {
        regForm.addEventListener('submit', e => {
            e.preventDefault()
            validateForm(regForm, [
                'name', 'email', 'password', 'password_confirmation', 'place_id'
            ])
        })
    }
}

const validateForm = (form, validations, rules) => {
    if (form.checkValidity()) {
        return form.submit()
    }

    // custom rules
    // input.setCustomValidity('')

    validations.forEach(name => {
        let field = form.elements?.[name]

        if (!field) return null

        if (field.checkValidity() === false) {
            // showError
            for (var rule in field.validity) {
                if (field.validity[rule]) {
                    let message = validationMessages?.[name]?.[rule]
                    if (!message) {
                        if (validationMessages[rule]) {
                            message = validationMessages[rule]
                        } else {
                            //
                        }
                    }
                    showError(field, message)

                    break
                }
            }
        } else {
            removeError(field)
        }
    })
}

const validationMessages = {
    valueMissing: 'Полето е задължително',
    tooShort: 'Полето не може да е по-малко от %s символа',
    email: {
        patternMismatch: 'Попълнете валиден email'
    },
    password: {
        tooShort: 'Паролата не може да е по-малка от 8 символа',
    }
}

const showError = (element, errorMessage) => {
    let errorDiv = qs('.error-message', element.parentElement)
    if (errorDiv) return errorDiv.innerHTML= errorMessage

    addElement(element.parentElement, 'div', errorMessage, [
        { name: 'class', val: 'error-message' }
    ])
}

const removeError = element => {
    let parent = element.parentElement
    let errorDiv = qs('.error-message', parent)
    if (errorDiv) errorDiv.remove()
}
