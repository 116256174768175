import { addElement, qs } from "../utils/utils"

export const showPageLoader = () => {
    let loaderWrap = qs('.page-loader')
    if (!loaderWrap) {
        loaderWrap = addElement(document.body, 'div', '', [
            { name: 'class', val: 'page-loader loader-wrap'}
        ])
        addElement(loaderWrap, 'div', '', [
            { name: 'class', val: 'loader'}
        ])
    } else {
        loaderWrap.style.display = ''
    }
}

export const hidePageLoader = () => {
    let loaderWrap = qs('.page-loader')
    if (loaderWrap) {
        loaderWrap.style.display = 'none'
    }
}

export const showLoader = (wrap, loader = 'overlay-loader') => {
    let loaderWrap = qs(`.${loader}`, wrap)
    if (!loaderWrap) {
        loaderWrap = addElement(wrap, 'div', '', [
            { name: 'class', val: `${loader} loader-wrap`}
        ])
        addElement(loaderWrap, 'div', '', [
            { name: 'class', val: 'loader'}
        ])
    } else {
        loaderWrap.style.display = ''
    }
}

export const hideLoader = (wrap) => {
    let loaderWrap = qs('.loader-wrap', wrap)
    if (loaderWrap) {
        loaderWrap.style.display = 'none'
    }
}
