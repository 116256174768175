import { qs, qsAll } from './utils/utils';
import './../../scss/main.scss'
import { filtering } from './modules/filters/filters';
import { auth } from './modules/auth/auth';
import { toggleEventSave } from './modules/favorites';
import { showLoader, showPageLoader } from './modules/loaders';
import { createConfirmModal } from './modules/modals';

document.addEventListener('DOMContentLoaded', (event) => {

    // Find listings wrap and init filtering
    if (qs('.events-listings-wrap'))
        filtering()

    // Auth pages
    if (qs('.auth-page'))
        auth()

    // Event toggle save
    toggleEventSave()

    // External links confirm
    // let extLinks = qsAll('.ext-link')
    // if (extLinks && extLinks.length) {
    document.body.addEventListener('click', e => {
        if (e.target.classList.contains('ext-link')) {
            e.preventDefault()
            let link = e.target.href

            // confirm modal
            let modal = createConfirmModal(
                'ex-link',
                'Напускане на сайта',
                `Това е външен линк, публикуван от организатора и може да е опасен!
                Достъпете на Ваша отговорност.`,
                e => { window.open(link, '_blank') },
                'modal-sm'
            )
        }
    })
    // }

    // showLoader(qs('.events-list'), 'overlay-top-loader')
})
