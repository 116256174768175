import { qs, qsAll, _, addElement } from '../utils/utils';

export const showToast = (message, position, type = 'info', permanent = false, time = 5000) => {
    let wrap = getToastWrap(position)

    let toast = addElement(wrap, 'div', '', [
        { name: 'class', val: 'toast ' }
    ])

    if (type) {
        toast.classList.add(`toast-${type}`)
        addIcon(toast, type)
    }

    addElement(toast, 'div', message, [
        { name: 'class', val: 'toast-body' }
    ])

    let toastClose = addElement(toast, 'div', '', [
        { name: 'class', val: 'toast-close' }
    ])

    toastClose.innerHTML = '<svg><use href="#close-x"></use></svg>'
    toastClose.addEventListener('click', e => {
        toast.remove()
    })

    if (!permanent) {
        setTimeout(() => toast.remove(), time)
    }
}

const getToastWrap = position => {
    let pos = position.split(' ').map(p => 'toast-' + p)
    let wrap = qs('.toast-wrap.' + pos.join('.'))

    return wrap ? wrap : addElement(document.body, 'div', '', [
        { name: 'class', val: 'toast-wrap ' + pos.join(' ') }
    ])
}

const addIcon = (wrap, type) => {
    let icon = addElement(wrap, 'div', '', [
        { name: 'class', val: 'toast-icon' }
    ])

    icon.innerHTML = `<svg><use href="#${type}"></use></svg>`

    // let icon = addElement(wrap, 'div', '', [
    //     { name: 'class', val: 'toast-icon' }
    // ])

    // let svg = addElement(icon, 'svg')
    // addElement(svg, 'use', '', [
    //     { name: 'href', val: `#${type}` }
    // ])

    return icon
}
