import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeBg from './locale-bg'

export const makeDatepicker = (el, options) => {
    return new AirDatepicker(el, {
        locale: localeBg,
        ...options
    })
}
