import axios from 'axios';
import { addElement, changeQsUrl, emitFilterChangeEvent, getQueryParameters, qs } from '../../utils/utils';
import { initDateFilters } from './date-filters';
import { initLocationFilters } from './location-filters';
import { hideLoader, hidePageLoader, showLoader, showPageLoader } from '../loaders';

const Dom = {
    // Search Term
    searchTermControl:  qs('#search-term'),
    searchTermBtn:      qs('#search-term-btn'),
    searchTermClearBtn: qs('#search-term-clear'),

    // Category
    categoryControl:    qs('#category'),

    // Location
    isOnlineCheckbox:   qs('#online'),
    area:               qs('#area'),
    place:              qs('#place'),
    placePlaceholder:   qs('#place-placeholder'),
    placeDropdown:      qs('.places-ul'),
    clearPlacesBtn:     qs('#clear-places'),
    searchPlaceBtn:     qs('#place-search-btn'),

    // Dates
    dateFrom:           qs('#date_from_start'),
    dateEnd:            qs('#date_from_end'),
    datesFiltersBtn:    qs('#dates-filter-btn'),
    dateOff:            qs('#date-off'),
    dateRadiosWrap:     qs('.date-filters-wrap'),
    filtersOff:         qs('#toggle-date-filters-off'),

    eventsWrap:         qs('.events-list'),
    pagingWrap:         qs('.events-paging-wrap'),

    // Results nav
    navResultsNum:      qs('.events-search-results-num'),
    navFilters: {
        date:           qs('.events-search-filter-date_from_start'),
        term:           qs('.events-search-filter-term'),
        category:       qs('.events-search-filter-category'),
        place:          qs('.events-search-filter-place'),
    }
}

let Listings = {
    query: null,
    page: 1,
    organizer: null,
    init: () => {
        Listings.query = getQueryParameters();
        Listings.page = Listings.query.get('page') || 1;

        let organizerWrap = qs('[data-organizer]')
        if (organizerWrap) {
            Listings.organizer = organizerWrap.dataset.organizer;
        }
    }
}

window.Listings = Listings

export const filtering = () => {
    Listings.init();

    initLocationFilters(Dom);
    initDateFilters(Dom);

    Dom.categoryControl.addEventListener('change', e => {
        emitFilterChangeEvent(e.target)
    });

    Dom.searchTermClearBtn.addEventListener('click', e => {
        if (Dom.searchTermControl.value.length) {
            Dom.searchTermControl.value = ''
            emitFilterChangeEvent(e.target)
        }
    });

    Dom.searchTermBtn.addEventListener('click', e => {
        if (Dom.searchTermControl.value.trim())
            emitFilterChangeEvent(e.target)
    });

    // let filters
    document.addEventListener('filter-changed', e => {
        Listings.page = 1;
        applyFilters(Dom, Listings);
    });

    Dom.pagingWrap.addEventListener('click', e => {
        e.preventDefault();

        if (e.target.classList.contains('event-paging-box')) {

            let page = e.target.innerText;

            if (page === '<') {
                page = Listings.page - 1;
            }

            if (page === '>') {
                page = Listings.page + 1;
            }

            Listings.page = page;

            applyFilters(Dom, Listings);
        }
    })
}

const applyFilters = (Dom, Listings) => {
    let filters = getFilters(Dom, Listings.page, Listings.organizer)

    let queryString = filters.map(filter => {
        return filter.name + '=' + encodeURIComponent(filter.value)
    }).join('&');

    showFiltersOnNav(filters)

    let url = `/events/all/render?${queryString}`;

    // showLoader(Dom.eventsWrap, 'overlay-top-loader')
    // showLoader(document.body, 'overlay-top-loader')
    showPageLoader()

    Dom.eventsWrap.innerHTML = 'Зареждане'
    Dom.navResultsNum.innerHTML = 'Зареждат се'

    axios.get(url)
        .then(response => {
            renderNewEvents(Dom, response.data)

            setTimeout(() => {
                // hideLoader(Dom.eventsWrap)
                hidePageLoader()

                if (Dom.eventsWrap.childElementCount) {
                    Dom.navResultsNum.innerHTML =
                        `Резултати: <strong>${Dom.eventsWrap.childElementCount}</strong>`
                } else {
                    Dom.navResultsNum.innerHTML =
                        `Няма резултати по следните критерии`
                }

                setTimeout(() => {
                    let content = qs('.home-listings-head')
                    if (content) content.scrollIntoView({
                        block: "start",
                        behavior: "smooth"
                    })
                }, 200)
            }, 1000)
            // Change url in browser
            // changeQsUrl($queryString)
        })
}

const showFiltersOnNav = filtersArr => {
    hideFiltersNav()

    let filters = {}
    filtersArr.forEach(filter => filters[filter.name] = filter.value)

    if (filters.online) {
        showFilterNavItem('place')
        qs('.events-search-filter-val', Dom.navFilters.place)
            .innerText = 'Онлайн'
    }

    if (filters.area || filters.place) {
        let locValues = []

        if (filters.place)
            locValues.push(qs('#place-placeholder').value)

        if (filters.area)
            locValues.push('общ. ' + qs(`[value="${filters.area}"`, Dom.area).innerHTML)

        qs('.events-search-filter-val', Dom.navFilters.place)
            .innerHTML = locValues.join(', ')

        showFilterNavItem('place')
    }

    if (filters.date_from_start) {
        let fixedFilters = {
            today: 'Днес',
            tomorrow: 'Утре',
            week: 'Тази седмица',
            month: 'Този месец'
        }

        let dateElem = qs('.events-search-filter-val', Dom.navFilters.date)
        if (Object.keys(fixedFilters).includes(filters.date_from_start)) {
            dateElem.innerText = fixedFilters[filters.date_from_start]
        } else {
            dateElem.innerText = filters.date_from_start
        }
        showFilterNavItem('date')
    }

    if (filters.category) {
        showFilterNavItem('category')
        let checked = qs('[name="category"]:checked')
        let nameEl = qs('.event-category-name', checked.parentElement)

        qs('.events-search-filter-val', Dom.navFilters.category)
            .innerText = nameEl.innerText
    }

    if (filters.term) {
        showFilterNavItem('term')
        let termElement = qs('.events-search-filter-val', Dom.navFilters.term)
        termElement.innerText = filters.term
    }
}

const hideFiltersNav = () => {
    Object.keys(Dom.navFilters).forEach(filter => {
        Dom.navFilters[filter].classList.remove('show')
    })
}

const showFilterNavItem = name => {
    Dom.navFilters[name].classList.add('show')
}

const getFilters = (Dom, page = 1, organizer = null) => {
    let filters = [];

    // Set organizer of events
    if (organizer) {
        filters.push({ name: 'user', value: organizer });
    }

    // Add page number
    filters.push({ name: 'page', value: page });

    // Search term
    filters.push({ name: 'term', value: Dom.searchTermControl.value.trim() })

    // Location
    if (Dom.isOnlineCheckbox.checked) {
        filters.push({ name: 'online', value: Dom.isOnlineCheckbox.checked ? 1 : null })
    } else {
        filters.push({ name: 'area', value: Dom.area.value})
        filters.push({ name: 'place', value: Dom.place.value})
    }

    // Date
    let checkRadio = qs('[name="date_start_period"]:checked');
    if (checkRadio && checkRadio.value) {
        filters.push({ name: 'date_from_start', value: checkRadio.value })
    } else {
        filters.push({ name: 'date_from_start', value: Dom.dateFrom.value })
        filters.push({ name: 'date_from_end', value: Dom.dateEnd.value })
    }

    // Category
    let categoryRadio = qs('[name="category"]:checked')
    if (categoryRadio.value) {
        filters.push({ name: 'category', value: categoryRadio.value })
    }

    return filters.filter(f => f.value );
}

const renderNewEvents = (Dom, data) => {
    let wrapHeight = Dom.eventsWrap.clientHeight
    Dom.eventsWrap.style.height = wrapHeight

    Dom.pagingWrap.innerHTML = data.paging;

    Dom.eventsWrap.classList.add('refreshing');

    setTimeout(() => {
        Dom.eventsWrap.classList.remove('refreshing');
        Dom.eventsWrap.innerHTML = data.html;
        Dom.eventsWrap.style.height = ''
    }, 500)
}
